import { usePage } from "./usePage"
import { useTerritories } from "./useTerritories"

export const usePagePickupTerritories = () => {
  const { page } = usePage()
  const {
    territories: { pickup },
    defaultPickupTerritory,
  } = page

  const { territories: pickupTerritories } = useTerritories(pickup)

  return {
    pickupTerritories,
    defaultPickupTerritory,
  }
}
