import { useContext } from "react";
import { CheckoutFormContextInterface, CheckoutFormContext } from "~/components/blocks/CheckoutBlock";
import { useEffectOnce } from "react-use";

export const useInitialiseFieldsList = (fields: string[]) => {
  const { initialiseFieldsList } = useContext<CheckoutFormContextInterface>(
    CheckoutFormContext
  )
  useEffectOnce(() => {
    initialiseFieldsList(fields)
  })
} 
