import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { StoreEntity } from "~/interfaces/entities/Store"
import { storesSelectors } from "~/redux/state/stores"
import { RootState } from "~/redux/store"

export const useStore = (id: EntityId) => {
  const store = useSelector<RootState, StoreEntity | undefined>(state =>
    storesSelectors.selectById(state, id)
  )

  return { store }
}
