import { FormControl, FormLabel, Select, SelectProps } from "@chakra-ui/react"
import React, { forwardRef, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { usePageDeliveryTerritories } from "~/hooks/usePageDeliveryTerritories"

interface Props extends SelectProps {
  label: string
  name: string
  errors: any
  value?: string
}

const CountryInput = forwardRef<HTMLSelectElement, Props>(
  ({ label, name, errors, value, ...rest }, ref) => {
    const { t } = useTranslation()
    const [selectValue, setSelectValue] = useState(value)
    const { deliveryTerritories } = usePageDeliveryTerritories()
    const hasOnlyOneCountryAvailable = deliveryTerritories.length === 1

    const onChangeSelect = useCallback(
      e => {
        setSelectValue(e.target.value)
      },
      [setSelectValue]
    )

    return (
      <FormControl id={name} isInvalid={errors[name]}>
        <FormLabel fontWeight="normal">{label}</FormLabel>

        <Select
          ref={ref}
          isDisabled={hasOnlyOneCountryAvailable}
          borderColor="gray.200"
          backgroundColor="white"
          name={name}
          placeholder={t("components.checkout.fields.CountryInput")}
          onChange={onChangeSelect}
          value={selectValue}
          {...rest}
        >
          {deliveryTerritories.map(country => {
            const { name, code } = country
            return (
              <option
                id={`country-option-${code.toLowerCase()}`}
                key={code}
                value={code}
              >
                {name}
              </option>
            )
          })}
        </Select>
      </FormControl>
    )
  }
)

export default React.memo(CountryInput)
