import md5 from "md5"
import { useApplicationState } from "./useApplicationState"
import { useAuth } from "./useAuth"

export function useLocalStorageKeys(): { [key: string]: string } {
  const application = useApplicationState()
  const { state: authState } = useAuth()

  let userPrefix = authState.isAuthenticated
    ? `${md5(authState.emailAddress)}_`
    : ""

  const localStorageAppBannerKey = application.localStorageAppBannerKey
  const localStorageCartKey = `${userPrefix}${application.localStorageCartKey}`
  const localStorageFulfillmentKey = `${userPrefix}${application.localStorageFulfillmentKey}`
  const localStorageUserKey = `${userPrefix}${application.localStorageUserKey}`
  const localStorageAddressKey = `${userPrefix}${application.localStorageAddressKey}`
  const localStorageAuthTokenKey = application.localStorageAuthTokenKey

  return {
    localStorageAppBannerKey,
    localStorageCartKey,
    localStorageFulfillmentKey,
    localStorageUserKey,
    localStorageAddressKey,
    localStorageAuthTokenKey,
  }
}
