import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useMemo } from "react"
import { usePageCartClientPrintService } from "~/hooks/usePageCartClientPrintService"

interface Props {
  children: React.ReactNode
}

const StripeElementsWrapper: React.FC<Props> = ({ children }) => {
  const {
    clientPrintService: { paymentConfig },
  } = usePageCartClientPrintService()

  const publishableKey = paymentConfig.stripe?.publishableKey

  const stripePromise = useMemo(
    () => (publishableKey ? loadStripe(publishableKey) : null),
    [publishableKey]
  )

  return <Elements stripe={stripePromise}>{children}</Elements>
}

export default StripeElementsWrapper
