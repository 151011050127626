import { Checkbox, FormControl, Text, Link, Box } from "@chakra-ui/react"
import React, { useState } from "react"
import { Controller } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useApplicationState } from "~/hooks/useApplicationState"

interface Props {
  name: string
  control: any
  errors: any
}

const TermsInput: React.FC<Props> = ({ name, control, errors }) => {
  const { t } = useTranslation()
  const { termsAndConditionsUrl, privacyPolicyUrl } = useApplicationState()

  const [checkboxFocus, setCheckBoxFocus] = useState(false)

  // Default highlight style by chakra? Not theme related.
  const focusStyle = `#3182ce 0 0 0 2px`

  return (
    <FormControl id={name} marginTop={6}>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        defaultValue={false}
        render={({ field: { onChange, onBlur, value, name } }) => {
          return (
            <Box id="p-checkout-terms-checkbox">
              <Checkbox
                isInvalid={errors[name]}
                onBlur={() => {
                  setCheckBoxFocus(false)
                  onBlur()
                }}
                onChange={e => onChange(e.target.checked)}
                isChecked={value || false}
                name={name}
                lineHeight="1.5"
                onFocus={() => {
                  setCheckBoxFocus(true)
                }}
                style={{ boxShadow: checkboxFocus ? focusStyle : "none" }}
              >
                <Text>
                  <Trans i18nKey="components.checkout.fields.TermsInput" t={t}>
                    <Link
                      id="p-checkout-terms-and-conditions"
                      color="blue.500"
                      href={termsAndConditionsUrl}
                      isExternal
                      _focusWithin={{ textDecoration: "underline" }}
                    />
                    <Link
                      id="p-checkout-privacy-policy"
                      color="blue.500"
                      href={privacyPolicyUrl}
                      isExternal
                      _focusWithin={{ textDecoration: "underline" }}
                    />
                  </Trans>
                </Text>
              </Checkbox>
            </Box>
          )
        }}
      />
    </FormControl>
  )
}

export default TermsInput
