import React from "react"
import { Control, useWatch } from "react-hook-form"
import { PickupFormFields } from "../PickupForm"
import PickupFormDetailsDefault from "./PickupFormDetailsDefault"
import PickupFormDetailsJP from "./PickupFormDetailsJP"

interface Props {
  control: Control<PickupFormFields>
}

const PickupFormDetails: React.FC<Props> = ({ control }) => {
  const country = useWatch({
    control,
    name: "country",
  })

  switch (country) {
    case "JP":
      return <PickupFormDetailsJP />
    case "US":
    case "NZ":
      return <PickupFormDetailsDefault />
    default:
      return <PickupFormDetailsDefault />
  }
}

export default PickupFormDetails
