import { Flex, useDisclosure } from "@chakra-ui/react"
import React, { FunctionComponent, useContext, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useEffectOnce } from "react-use"
import { useCart } from "~/hooks/useCart"
import {
  CheckoutFormContext,
  CheckoutFormContextInterface,
} from "../../blocks/CheckoutBlock"
import { DeliveryFormFields } from "../DeliveryForm"
import TermsInput from "../fields/TermsInput"
import FormAction from "../FormAction"
import { onEnterKeyFocusNext } from "../FormFocusHelper"
import FormHeading from "../FormHeading"
import { PAYMENT_METHOD_KEY, usePaymentOption } from "./hooks/usePaymentOption"
import PaymentDrawer from "./PaymentDrawer"
import PaymentFormWatcher from "./PaymentFormWatcher"

interface Props {
  FormDetails: FunctionComponent<any>
  fields: string[]
  defaultValues: { [key: string]: string }
  orderSuccessPath: string
  onSubmit: (data: any) => void
  onChange: (data: any) => void
  isLoading: boolean
  isDisabled: boolean
  isReady: boolean
}

const PaymentFormMobile: React.FC<Props> = ({
  onSubmit,
  onChange,
  isLoading,
  isDisabled,
  isReady,
  defaultValues,
  orderSuccessPath,
  FormDetails,
  fields,
}) => {
  const { t } = useTranslation()
  const { onOpen, isOpen, onClose } = useDisclosure()
  const methods = useForm<DeliveryFormFields>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    formState: { dirtyFields, touchedFields, errors, isSubmitSuccessful },
    reset,
    setValue,
    getValues,
  } = methods

  const {
    cart: { paymentIntent: { clientSecret } = {} },
  } = useCart()

  const { option, hasStripe, isFree } = usePaymentOption()

  useEffectOnce(() => {
    if (hasStripe) {
      setValue(PAYMENT_METHOD_KEY, option)
    }
  })

  useEffect(() => {
    reset({
      ...getValues(),
      ...defaultValues,
    })
  }, [JSON.stringify(defaultValues), getValues, reset])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
    }
  }, [getValues, isSubmitSuccessful, reset])

  const { fieldsList } =
    useContext<CheckoutFormContextInterface>(CheckoutFormContext)

  const enhancedOnSubmit = (data: DeliveryFormFields) => {
    if (isFree) {
      onSubmit(data)
      return
    }

    if (clientSecret && hasStripe) {
      //if there is clientSecret
      //open up the payment input drawer straight away
      onOpen()
    } else {
      onSubmit(data)
      onOpen()
    }
  }

  return (
    <FormProvider {...methods}>
      <Flex
        direction="column"
        flex={1}
        as="form"
        onSubmit={handleSubmit(enhancedOnSubmit)}
        onKeyDown={e =>
          onEnterKeyFocusNext(
            e,
            getValues(),
            dirtyFields,
            touchedFields,
            errors,
            fieldsList,
            false
          )
        }
      >
        <FormHeading>
          {t("components.checkout.DeliveryForm.Heading")}
        </FormHeading>
        <FormDetails control={control} />
        <TermsInput name="terms" control={control} errors={errors} />
        {hasStripe && (
          <PaymentDrawer
            orderSuccessPath={orderSuccessPath}
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
          />
        )}
        <FormAction
          isLoading={isLoading}
          isDisabled={isDisabled}
          isReady={isReady}
        />
        <PaymentFormWatcher
          control={control}
          onChange={onChange}
          fields={fields}
        />
      </Flex>
    </FormProvider>
  )
}

export default PaymentFormMobile
