import { Stack } from "@chakra-ui/react"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useUnmount, useUpdateEffect } from "react-use"
import { useInitialiseFieldsList } from "~/hooks/useInitialiseFieldsList"
import { unregisterFields } from "~/services/Utils"
import CountryInput from "../fields/CountryInput"
import EmailInput from "../fields/EmailInput"
import FormInput from "../fields/FormInput"
import PhoneInput from "../fields/PhoneInput"
import StateInput from "../fields/StateInput"
import FormStack from "../FormStack"

// keep this list ordered by UI input
const fields = [
  "firstName",
  "lastName",
  "fullName",
  "postcode",
  "state",
  "line1",
  "line2",
  "phoneNumber",
  "email",
  "country",
  "terms",
]

interface Props {}

const DeliveryFormDetailsJP: React.FC<Props> = () => {
  const { t } = useTranslation()
  const {
    register,
    unregister,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext()

  useInitialiseFieldsList(fields)

  useUnmount(() => {
    unregisterFields(unregister, fields)
  })

  const countryCode = watch("country")
  const firstAndLastName = watch(["firstName", "lastName"])

  useUpdateEffect(() => {
    setValue("fullName", firstAndLastName.join(" "))
  }, [firstAndLastName])

  return (
    <FormStack>
      <Stack w="full" direction={{ base: "column", md: "row" }} spacing={4}>
        <FormInput
          label={t(
            "components.checkout.delivery-forms.DeliveryFormDetails.JP.LastName"
          )}
          type="text"
          autoComplete="family-name"
          errors={errors}
          defaultValue={getValues("lastName")}
          {...register("lastName", {
            required: true,
          })}
        />
        <FormInput
          label={t(
            "components.checkout.delivery-forms.DeliveryFormDetails.JP.FirstName"
          )}
          type="text"
          autoComplete="given-name"
          errors={errors}
          defaultValue={getValues("firstName")}
          {...register("firstName", {
            required: true,
          })}
        />
      </Stack>
      <input
        type="hidden"
        defaultValue={`${getValues("firstName")} ${getValues("lastName")}`}
        {...register("fullName", {
          required: true,
        })}
      />
      <Stack w="full" direction={{ base: "column", md: "row" }} spacing={4}>
        <FormInput
          label={t(
            "components.checkout.delivery-forms.DeliveryFormDetails.JP.Postcode"
          )}
          type="text"
          autoComplete="postal-code"
          errors={errors}
          defaultValue={getValues("postcode")}
          {...register("postcode", {
            required: true,
          })}
        />
        <StateInput
          countryCode={countryCode}
          label={t(
            "components.checkout.delivery-forms.DeliveryFormDetails.JP.State"
          )}
          errors={errors}
          {...register("state", {
            required: true,
          })}
        />
      </Stack>

      <FormInput
        label={t(
          "components.checkout.delivery-forms.DeliveryFormDetails.JP.Line1"
        )}
        type="text"
        autoComplete="address-line1"
        errors={errors}
        defaultValue={getValues("line1")}
        {...register("line1", {
          required: true,
        })}
      />
      <FormInput
        label={t(
          "components.checkout.delivery-forms.DeliveryFormDetails.JP.Line2"
        )}
        type="text"
        autoComplete="address-line2"
        errors={errors}
        defaultValue={getValues("line2")}
        {...register("line2", {
          required: false,
        })}
      />

      <Stack w="full" direction={{ base: "column", md: "row" }} spacing={4}>
        <PhoneInput
          label={t(
            "components.checkout.delivery-forms.DeliveryFormDetails.JP.PhoneNumber"
          )}
          errors={errors}
          countryCode={countryCode}
          defaultValue={getValues("phoneNumber")}
          {...register("phoneNumber", {
            required: true,
          })}
        />
        <EmailInput />
      </Stack>

      <CountryInput
        label={t(
          "components.checkout.delivery-forms.DeliveryFormDetails.JP.Country"
        )}
        errors={errors}
        value={getValues("country")}
        {...register("country", {
          required: true,
        })}
      />
    </FormStack>
  )
}

export default DeliveryFormDetailsJP
