import {
  Box,
  BoxProps,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiCheck } from "react-icons/fi"
import { RiCoupon3Line } from "react-icons/ri"
import { useCart } from "~/hooks/useCart"
import { useCoupon } from "~/hooks/useCoupon"
import { usePrintService } from "~/hooks/usePrintService"

interface Props extends BoxProps {}

const CouponCodeInput: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation()
  const { cart } = useCart()
  const { printService } = usePrintService(cart.printServiceId)

  const { couponType, couponCodeTitle } = cart

  const { isOpen: isCouponVisible, onOpen: displayCoupon } = useDisclosure()

  const {
    couponIsLoading,
    couponIsValid,
    couponIsInvalid,
    couponInput,
    setCouponInput,
    onCouponApply,
    onCouponReset,
  } = useCoupon({ isCouponVisible })

  const isCouponAvailable = printService && !!printService.hasCoupon
  if (!isCouponAvailable) {
    return null
  }

  return (
    <Box maxWidth="xs" {...props}>
      {couponInput && couponIsValid ? (
        <Button
          id="coupon-applied"
          leftIcon={<FiCheck />}
          fontSize="sm"
          fontWeight="normal"
          variant="link"
          color="green.500"
          padding={0}
          marginBottom={2}
          _hover={{ textDecoration: "none", cursor: "default" }}
        >
          {couponType === "coupon" &&
            t("components.checkout.fields.CouponCodeInput.Valid")}
          {couponType === "gift card" &&
            t("components.checkout.fields.CouponCodeInput.GiftCardValid")}
        </Button>
      ) : (
        <Button
          id="p-checkout-coupon"
          leftIcon={<RiCoupon3Line />}
          fontSize="sm"
          fontWeight="normal"
          variant="link"
          color="gray.500"
          padding={0}
          marginBottom={2}
          onClick={displayCoupon}
          _hover={
            isCouponVisible
              ? { textDecoration: "none", cursor: "default" }
              : { textDecoration: "underline" }
          }
        >
          {t("components.checkout.fields.CouponCodeInput.Open")}
        </Button>
      )}
      <InputGroup hidden={!isCouponVisible}>
        <Input
          data-testid="coupon-code-input"
          id="coupon-code-input"
          borderColor="gray.200"
          backgroundColor="white"
          name="couponCode"
          placeholder={t("components.checkout.fields.CouponCodeInput.Input")}
          autoComplete="off"
          value={couponInput}
          onChange={e => setCouponInput(e.target.value)}
          disabled={couponIsValid || couponIsLoading}
          _disabled={{ backgroundColor: "gray.200" }}
          isInvalid={couponIsInvalid}
          onKeyDown={e => {
            if (e.key === "Enter") {
              onCouponApply()
            }
          }}
        />

        <InputRightElement
          padding={0}
          width="auto"
          children={
            couponIsValid ? (
              <Button
                id="p-customer-remove-coupon" // GTM: remove coupon
                colorScheme="gray"
                variant="link"
                width="5.5rem"
                onClick={() => onCouponReset()}
              >
                {t("components.checkout.fields.CouponCodeInput.Change")}
              </Button>
            ) : (
              <Button
                id="p-customer-apply-coupon"
                colorScheme="primary"
                variant="link"
                width="5.5rem"
                onClick={onCouponApply}
                isLoading={couponIsLoading}
              >
                {t("components.checkout.fields.CouponCodeInput.Apply")}
              </Button>
            )
          }
        />
      </InputGroup>

      {couponIsInvalid && (
        <Text
          id="coupon-error-message"
          fontSize="sm"
          color="red.400"
          marginTop={1}
        >
          {Boolean(couponCodeTitle)
            ? couponCodeTitle
            : t("components.checkout.fields.CouponCodeInput.Invalid")}
        </Text>
      )}
    </Box>
  )
}

export default CouponCodeInput
