import { flatten } from "lodash"
import { useApplicationState } from "./useApplicationState"
import { useCart } from "./useCart"
import { useLineItems } from "./useLineItems"
import { usePrintService } from "./usePrintService"
import { useProductPrices } from "./useProductPrices"
import { useProducts } from "./useProducts"
import { useSelectedStore } from "./useSelectedStore"

export const useCurrentCurrencyCode = (): string => {
  const { store } = useSelectedStore()

  const {
    cart: { lineItemIds, printServiceId, fulfillment },
  } = useCart()

  const { defaultCurrency: appDefaultCurrency } = useApplicationState()
  const {
    printService: { defaultCurrency: printServiceDefaultCurrency },
  } = usePrintService(printServiceId)

  const appCurrency = appDefaultCurrency ?? "USD"
  const currencyFallback = printServiceDefaultCurrency ?? appCurrency

  const { lineItems } = useLineItems(lineItemIds)

  const { products } = useProducts(
    lineItems.map(lineItem => lineItem.productId)
  )

  const priceIds = flatten(products.map(product => product?.prices))

  const { productPrices } = useProductPrices(priceIds)

  const matchedPrice = productPrices.find(
    productPrice => productPrice?.retailerId === store?.retailerId
  )

  const pickupPrice = matchedPrice?.currency ?? currencyFallback
  const deliveryPrice = appCurrency

  return fulfillment === "pickup" ? pickupPrice : deliveryPrice
}
