import { Box, Stack } from "@chakra-ui/react"
import { BlockCheckoutOrderSummaryDisplayType } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { Address } from "~/interfaces/entities/Address"
import { OrderSummaryEntity } from "~/interfaces/entities/OrderSummary"
import { StoreEntity } from "~/interfaces/entities/Store"
import FormHeading from "../FormHeading"
import OrderSummaryAddress from "./OrderSummaryAddress"
import OrderSummaryAddressNotRequired from "./OrderSummaryAddressNotRequired"
import OrderSummaryAddressRequired from "./OrderSummaryAddressRequired"
import OrderSummaryMcDonald from "./OrderSummaryMcDonald"

interface Props {
  store?: StoreEntity
  order: OrderSummaryEntity
  headingText?: string
  orderSummaryDisplayType?: BlockCheckoutOrderSummaryDisplayType
}

const OrderSummary: React.FC<Props> = ({
  store,
  order,
  headingText,
  orderSummaryDisplayType = "hiddenByDefault",
}) => {
  const { t } = useTranslation()

  const storeAddress: Address = {
    name: store?.name,
    line1: store?.address || "",
    line2: store?.addressLine2,
    city: store?.city || "",
    state: store?.state || "",
    country: store?.country || "",
    postcode: store?.postcode || "",
  }

  let summaryComponent = null

  if (!order) {
    summaryComponent = null
  } else if (orderSummaryDisplayType === "mcDonald") {
    summaryComponent = (
      <OrderSummaryMcDonald order={order} headingText={headingText} />
    )
  } else if (orderSummaryDisplayType === "visibleByDefault") {
    summaryComponent = (
      <OrderSummaryAddressNotRequired order={order} headingText={headingText} />
    )
  } else {
    summaryComponent = (
      <OrderSummaryAddressRequired order={order} headingText={headingText} />
    )
  }

  return (
    <Stack spacing={8}>
      {store && (
        <Box>
          <FormHeading>
            {t("components.checkout.OrderSummary.Heading")}
          </FormHeading>
          <OrderSummaryAddress
            address={storeAddress}
            isPickup={true}
            showHeader={false}
          />
        </Box>
      )}
      {summaryComponent}
    </Stack>
  )
}

export default OrderSummary
