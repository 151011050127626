import { FormControl, FormLabel, Select, SelectProps } from "@chakra-ui/react"
import React, { forwardRef } from "react"
import { listOfStatesByCountryCode } from "~/services/Utils"

interface Props extends SelectProps {
  countryCode: string
  name: string
  errors: any
  label?: string
}

const StateInput = forwardRef<HTMLSelectElement, Props>(
  ({ countryCode, label = "", name, errors, ...rest }, ref) => {
    const stateCodes = listOfStatesByCountryCode(countryCode)

    return (
      <FormControl id={name} isInvalid={errors[name]}>
        {label && <FormLabel fontWeight="normal">{label}</FormLabel>}
        <Select
          ref={ref}
          borderColor="gray.200"
          backgroundColor="white"
          name={name}
          {...rest}
        >
          {stateCodes.map((state: any) => (
            <option
              id={`state-option-${state.state_code.toLowerCase()}`}
              key={state.state_code}
              value={state.state_code}
            >
              {state.name}
            </option>
          ))}
        </Select>
      </FormControl>
    )
  }
)

export default React.memo(StateInput)
