import { Box, Button } from "@chakra-ui/react"
import { PaymentElement } from "@stripe/react-stripe-js"
import React, { FormEvent } from "react"
import ErrorPanel from "~/components/ErrorPanel"
import { useOrderSummary } from "~/hooks/useOrderSummary"
import { useStripePayment } from "./hooks/useStripePayment"

interface Props {
  orderSuccessPath: string
}

const StripeInputMobile: React.FC<Props> = ({ orderSuccessPath }) => {
  const { onPaymentSubmit, isLoading, errorMessage } =
    useStripePayment(orderSuccessPath)
  const {
    orderSummary: {
      data: { totalHuman },
    },
  } = useOrderSummary()

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    onPaymentSubmit()
  }

  return (
    <Box as="form" mt={4} onSubmit={handleSubmit}>
      <PaymentElement
        options={{
          fields: {
            billingDetails: {
              address: {
                country: "never",
              },
            },
          },
          //disable google pay and apple pay to avoid warnings
          wallets: { applePay: "never", googlePay: "never" },
        }}
      />
      <Button
        type="submit"
        size="lg"
        w="full"
        mt={8}
        colorScheme="primary"
        color="primary.text"
        isLoading={isLoading}
      >
        Pay {totalHuman}
      </Button>
      {Boolean(errorMessage) && <ErrorPanel mt={3} error={errorMessage} />}
    </Box>
  )
}

export default StripeInputMobile
