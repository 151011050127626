import { usePage } from "./usePage"
import { useTerritories } from "./useTerritories"

export const usePageDeliveryTerritories = () => {
  const { page } = usePage()
  const {
    territories: { delivery },
    defaultDeliveryTerritory,
  } = page

  const { territories: deliveryTerritories } = useTerritories(delivery)

  return {
    deliveryTerritories,
    defaultDeliveryTerritory,
  }
}
