import { useBreakpointValue } from "@chakra-ui/react"
import React from "react"
import { PaymentFields } from "~/interfaces/entities/Payment"
import DeliveryFormDetails from "./delivery-forms/DeliveryFormDetails"
import { usePaymentOption } from "./payment/hooks/usePaymentOption"
import PaymentFormDesktop from "./payment/PaymentFormDesktop"
import PaymentFormMobile from "./payment/PaymentFormMobile"
import StripeElementsWrapper from "./payment/stripe/StripeElementsWrapper"

const fields: string[] = [
  "fullName",
  "email",
  "line1",
  "line2",
  "city",
  "state",
  "postcode",
  "country",
  "phoneNumber",
  "terms",
  "payment.method",
  "payment.data.stripe.cardToken",
]

export interface DeliveryFormFields {
  fullName: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  country: string
  line1: string
  line2: string
  city: string
  postcode: string
  state: string
  payment: PaymentFields | null
}

interface Props {
  defaultValues: { [key: string]: string }
  orderSuccessPath: string
  onSubmit: (data: DeliveryFormFields) => void
  onChange: (data: DeliveryFormFields) => void
  isLoading: boolean
  isDisabled: boolean
  isReady: boolean
}

const DeliveryForm: React.FC<Props> = props => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })

  const { hasStripe, hasPayInStore } = usePaymentOption()

  const hasPaymentOption = Boolean(hasStripe || hasPayInStore)

  if (!hasPaymentOption) {
    console.error("No payment option available for delivery form")
  }

  return (
    <StripeElementsWrapper>
      {Boolean(isMobile && hasStripe) ? (
        <PaymentFormMobile
          {...props}
          fields={fields}
          FormDetails={DeliveryFormDetails}
        />
      ) : (
        <PaymentFormDesktop
          {...props}
          fields={fields}
          FormDetails={DeliveryFormDetails}
        />
      )}
    </StripeElementsWrapper>
  )
}

export default DeliveryForm
