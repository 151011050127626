import { Box, Button } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useCurrentCurrencyCode } from "~/hooks/useCurrentCurrencyCode"
import Note from "../Note"

interface Props {
  text?: string
  isLoading: boolean
  isDisabled?: boolean
  isReady: boolean
}

const FormAction: React.FC<Props> = ({
  text,
  isLoading,
  isDisabled = false,
  isReady,
}) => {
  const { t } = useTranslation()

  const currency = useCurrentCurrencyCode()

  let label = text ?? t("components.checkout.FormAction.label.default")

  if (!isReady) {
    label = t("components.checkout.FormAction.label.uploading")
  }

  if (isLoading) {
    label = t("components.checkout.FormAction.label.placing")
  }

  return (
    <>
      <Box mt={6} mb={2}>
        <Button
          id="p-checkout-place-order"
          transition="all 0.2s ease-in-out"
          fontSize="xl"
          size="lg"
          colorScheme="primary"
          color="primary.text"
          width="full"
          type="submit"
          isDisabled={isDisabled || isLoading || !isReady}
        >
          {label}
        </Button>
      </Box>
      <Note>{t("components.checkout.FormAction.secureNote")}</Note>
      <Note>
        {t("components.checkout.FormAction.currencyNote", {
          defaultCurrency: currency,
        })}
      </Note>
    </>
  )
}

export default FormAction
