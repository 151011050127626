import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Spinner,
} from "@chakra-ui/react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useMemo } from "react"
import { usePageCartClientPrintService } from "~/hooks/usePageCartClientPrintService"
import StripeInputMobile from "./stripe/StripeInputMobile"

interface Props {
  orderSuccessPath: string
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
}

const PaymentDrawer: React.FC<Props> = ({
  orderSuccessPath,
  isOpen,
  onClose,
}) => {
  const {
    pageCart: { paymentIntent: { clientSecret } = {} },
    clientPrintService: {
      paymentConfig: {
        stripe: { publishableKey },
      },
    },
  } = usePageCartClientPrintService()

  const stripePromise = useMemo(
    () => loadStripe(publishableKey),
    [publishableKey]
  )

  const isPaymentIntentsLoaded = Boolean(clientSecret)

  return (
    <Drawer size="xl" placement="bottom" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent h={500} borderTopRadius="md">
        <DrawerCloseButton />
        {isPaymentIntentsLoaded ? (
          <DrawerBody pt={12}>
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <StripeInputMobile orderSuccessPath={orderSuccessPath} />
            </Elements>
          </DrawerBody>
        ) : (
          <Flex h="full" direction="column" justify="center" align="center">
            <Spinner size="lg" />
          </Flex>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default PaymentDrawer
