import { usePageDeliveryTerritories } from "./usePageDeliveryTerritories"
import { usePagePickupTerritories } from "./usePagePickupTerritories"

export const usePageTerritories = () => {
  const {
    pickupTerritories,
    defaultPickupTerritory,
  } = usePagePickupTerritories()
  const {
    deliveryTerritories,
    defaultDeliveryTerritory,
  } = usePageDeliveryTerritories()

  const pageTerritories = [...deliveryTerritories, ...defaultDeliveryTerritory]

  return {
    pageTerritories,
    pickupTerritories,
    defaultPickupTerritory,
    deliveryTerritories,
    defaultDeliveryTerritory,
  }
}
