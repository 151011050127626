import { Stack } from "@chakra-ui/react"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useUnmount, useUpdateEffect } from "react-use"
import { useInitialiseFieldsList } from "~/hooks/useInitialiseFieldsList"
import { unregisterFields } from "~/services/Utils"
import EmailInput from "../fields/EmailInput"
import FormInput from "../fields/FormInput"
import PhoneInput from "../fields/PhoneInput"
import FormStack from "../FormStack"

// keep this list ordered by UI elements
const fields = [
  "firstName",
  "lastName",
  "fullName",
  "phoneNumber",
  "email",
  "country",
  "terms",
]

interface Props {}

const PickupFormDetailsJP: React.FC<Props> = () => {
  const {
    register,
    unregister,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext()
  const { t } = useTranslation()
  useUnmount(() => {
    unregisterFields(unregister, fields)
  })

  useInitialiseFieldsList(fields)

  const countryCode = watch("country")
  const firstAndLastName = watch(["firstName", "lastName"])

  useUpdateEffect(() => {
    setValue("fullName", firstAndLastName.join(" "))
  }, [firstAndLastName])

  return (
    <FormStack>
      <Stack w="full" direction={{ base: "column", md: "row" }} spacing={4}>
        <FormInput
          label={t(
            "components.checkout.pickup-forms.PickupFormDetailsJP.LastName"
          )}
          type="text"
          autoComplete="family-name"
          errors={errors}
          defaultValue={getValues("lastName")}
          {...register("lastName", {
            required: true,
          })}
        />
        <FormInput
          label={t(
            "components.checkout.pickup-forms.PickupFormDetailsJP.FirstName"
          )}
          type="text"
          autoComplete="given-name"
          errors={errors}
          defaultValue={getValues("firstName")}
          {...register("firstName", {
            required: true,
          })}
        />
      </Stack>
      <input
        type="hidden"
        defaultValue={`${getValues("firstName")} ${getValues("lastName")}`}
        {...register("fullName", {
          required: true,
        })}
      />
      <Stack w="full" direction={{ base: "column", md: "row" }} spacing={4}>
        <PhoneInput
          label={t(
            "components.checkout.pickup-forms.PickupFormDetailsJP.PhoneNumber"
          )}
          errors={errors}
          countryCode={countryCode}
          defaultValue={getValues("phoneNumber")}
          {...register("phoneNumber", {
            required: true,
          })}
        />
        <EmailInput />
      </Stack>
    </FormStack>
  )
}

export default PickupFormDetailsJP
