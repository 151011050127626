import { usePageCart } from "./usePageCart"
import { usePrintService } from "./usePrintService"

/**
 * Return the print service associated to the current page cart
 * @returns PrintServiceEntity
 */
export const usePageCartPrintService = () => {
  const { pageCart } = usePageCart()
  const { printService } = usePrintService(pageCart.printServiceId)

  return {
    pageCart,
    printService,
  }
}
