import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { territoriesSelectors } from "~/redux/state/territories"
import { RootState } from "~/redux/store"

export const useTerritories = (ids?: EntityId[]) => {
  const territories = useSelector((state: RootState) =>
    ids
      ? territoriesSelectors.selectByIds(state, ids)
      : territoriesSelectors.selectAll(state)
  )

  return { territories }
}
