import { useSelector } from "react-redux"
import { clientPrintServicesSelectors } from "~/redux/state/clientPrintServices"
import { RootState } from "~/redux/store"
import { usePageCartPrintService } from "./usePageCartPrintService"

/**
 * Return the ClientPrintService associated to the current PrintService's remoteId
 * @returns ClientPrintServiceEntity
 */
export const usePageCartClientPrintService = () => {
  const { pageCart, printService } = usePageCartPrintService()
  const { remoteId } = printService

  const clientPrintService = useSelector((state: RootState) =>
    clientPrintServicesSelectors.makeSelectByRemotePrintServiceId()(
      state,
      remoteId
    )
  )!

  return {
    pageCart,
    printService,
    clientPrintService,
  }
}
