import { useSelector } from "react-redux"
import { orderSummariesSelector } from "~/redux/state/orderSummaries"
import { RootState } from "~/redux/store"
import { usePageSession } from "./usePageSession"

export const useOrderSummary = () => {
  const { pageSession } = usePageSession()
  const orderSummary = useSelector((state: RootState) =>
    orderSummariesSelector.selectById(state, pageSession.orderSummaryId)
  )!

  return { orderSummary }
}
