import React from "react"
import { usePageCart } from "~/hooks/usePageCart"
import StripeInput from "./StripeInput"

interface Props {}

const StripeOption: React.FC<Props> = () => {
  const {
    pageCart: { paymentIntent },
  } = usePageCart()

  if (!paymentIntent?.clientSecret) {
    return null
  }

  return <StripeInput />
}

export default StripeOption
