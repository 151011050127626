import React from "react"
import { Control, useFormContext, useWatch } from "react-hook-form"
import { useUpdateEffect } from "react-use"
import { useProcessActions } from "~/hooks/useProcessActions"

interface Props {
  control: Control<{ [key: string]: string }>
  onChange: (data: any) => void
  fields: any[]
}

const PaymentFormWatcher: React.FC<Props> = ({ control, onChange, fields }) => {
  const process = useProcessActions()

  const watchFields = useWatch({
    control,
    name: fields,
  })

  const {
    formState: { isDirty },
  } = useFormContext()

  useUpdateEffect(() => {
    const data: any = {}
    fields.forEach((name, index) => {
      data[name] = watchFields[index]
    })

    if (isDirty) {
      process.fireAnalyticEvent({ eventType: "beginCheckout", data: {} })
    }

    onChange(data)
  }, [JSON.stringify(watchFields)])

  return null
}

export default PaymentFormWatcher
