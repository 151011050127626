import { BoxProps, Text } from "@chakra-ui/react"
import React from "react"

interface Props extends BoxProps {}

const Note: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Text fontSize="sm" color="gray.500" {...rest}>
      {children}
    </Text>
  )
}

export default Note
