import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
} from "@chakra-ui/react"
import React, { forwardRef } from "react"

interface Props extends InputProps {
  label?: string
  name: string
  errors: any
  type: string
  leftEl?: any
  rightEl?: any
}

const FormInput = forwardRef<HTMLInputElement, Props>(
  ({ label, errors, name, leftEl = null, rightEl = null, ...props }, ref) => {
    return (
      <FormControl
        isInvalid={errors[name]}
        id={`p-checkout-${name.toLowerCase()}`}
      >
        {label && <FormLabel fontWeight="normal">{label}</FormLabel>}
        <InputGroup>
          {leftEl}
          <Input
            ref={ref}
            width="100%"
            borderColor="gray.200"
            backgroundColor="white"
            name={name}
            {...props}
          />
          {rightEl}
        </InputGroup>
      </FormControl>
    )
  }
)

export default FormInput
