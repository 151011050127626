import { Dictionary } from "@jackfruit/common"
import { DeepMap, FieldError } from "react-hook-form"
import { DeliveryFormFields } from "./DeliveryForm"
import { PickupFormFields } from "./PickupForm"

/**
 *  An input field should skip focus if:
 *  - It is hidden but not masked
 *  - It is disabled
 */
export const inputFieldShouldSkipFocus = (
  fieldName: string,
  htmlElementsInForm: any[]
) => {
  for (const el of Object.values(htmlElementsInForm)) {
    const name: string = el.name
    if (name && name.includes(fieldName)) {
      if (el.disabled) {
        return true
      }
      if (el.type && el.type !== "hidden") {
        // name matches and field not hidden
        return false
      }
    }
  }
  return true // cannot find unhidden field
}

export const focusHtmlElement = (
  fieldName: string,
  htmlElementsInForm: any[]
) => {
  Object.values(htmlElementsInForm).forEach((el: any) => {
    const name: string = el.name
    if (name && name.includes(fieldName)) {
      if (el.type && el.type !== "hidden") {
        el.focus()
      }
    }
  })
}

export const onEnterKeyFocusNext = (
  e: any,
  formFields: PickupFormFields | DeliveryFormFields,
  dirtyFields:
    | DeepMap<PickupFormFields, true>
    | DeepMap<DeliveryFormFields, true>,
  touchedFields:
    | DeepMap<PickupFormFields, true>
    | DeepMap<DeliveryFormFields, true>,
  errors:
    | DeepMap<PickupFormFields, FieldError>
    | DeepMap<DeliveryFormFields, FieldError>,
  fieldsList: string[],
  isPickupForm: boolean
) => {
  const keyCode = e.keyCode ? e.keyCode : e.which

  // On enter key, focus on next unfilled field
  if (keyCode === 13) {
    e.preventDefault()

    const htmlElementsInForm = e.target.form
    const currentFieldName = e.target.name
    const currentFieldIndex = fieldsList.findIndex(f => f === currentFieldName)
    if (currentFieldIndex === -1) {
      return
    } else {
      // Loop over all fields twice to ensure going back to unfilled filed is possible
      for (let i = currentFieldIndex + 1; i < fieldsList.length * 2; i++) {
        const index = i % fieldsList.length // if i gets to the last index, loop back to 0

        const fieldName = fieldsList[index]
        let fieldIsEmpty: boolean

        // some block casting to make typescript happy :)
        if (isPickupForm) {
          const fieldKey = fieldName as keyof PickupFormFields
          const pickupFormFields = formFields as PickupFormFields
          fieldIsEmpty = !pickupFormFields[fieldKey]
        } else {
          const fieldKey = fieldName as keyof DeliveryFormFields
          const deliveryFormFields = formFields as DeliveryFormFields
          fieldIsEmpty = !deliveryFormFields[fieldKey]
        }

        if (
          !fieldIsEmpty ||
          inputFieldShouldSkipFocus(fieldName, htmlElementsInForm)
        ) {
          continue
        }

        const fieldIsError = Object.keys(errors).includes(fieldName)
        const fieldNeverTouched =
          !Object.keys(touchedFields).includes(fieldName)
        const fieldNotDirty = !Object.keys(dirtyFields).includes(fieldName)

        if (
          fieldIsEmpty ||
          fieldIsError ||
          (fieldNeverTouched && fieldNotDirty)
        ) {
          focusHtmlElement(fieldName, htmlElementsInForm)
          break
        }
      }
    }
  }
}
