import React from "react"
import { Control, useWatch } from "react-hook-form"
import { DeliveryFormFields } from "../DeliveryForm"
import DeliveryFormDetailsCA from "./DeliveryFormDetailsCA"
import DeliveryFormDetailsDefault from "./DeliveryFormDetailsDefault"
import DeliveryFormDetailsGB from "./DeliveryFormDetailsGB"
import DeliveryFormDetailsJP from "./DeliveryFormDetailsJP"
import DeliveryFormDetailsNZ from "./DeliveryFormDetailsNZ"
import DeliveryFormDetailsUS from "./DeliveryFormDetailsUS"
import DeliveryFormDetailsZA from "./DeliveryFormDetailsZA"

interface Props {
  control: Control<DeliveryFormFields>
}

const DeliveryFormDetails: React.FC<Props> = ({ control }) => {
  const country = useWatch({
    control,
    name: "country",
  })

  switch (country) {
    case "US":
      return <DeliveryFormDetailsUS />
    case "GB":
      return <DeliveryFormDetailsGB />
    case "CA":
      return <DeliveryFormDetailsCA />
    case "ZA":
      return <DeliveryFormDetailsZA />
    case "NZ":
      return <DeliveryFormDetailsNZ />
    case "JP":
      return <DeliveryFormDetailsJP />
    default:
      return <DeliveryFormDetailsDefault />
  }
}

export default DeliveryFormDetails
