import { StackProps, VStack } from "@chakra-ui/react"
import React from "react"

interface Props extends StackProps {
  children: React.ReactNode
}

const FormStack: React.FC<Props> = ({ children, ...props }) => {
  return (
    <VStack spacing={4} {...props}>
      {children}
    </VStack>
  )
}

export default FormStack
