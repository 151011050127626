import { useToast } from "@chakra-ui/react"
import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useEffectOnce } from "react-use"
import { useRecoilState } from "recoil"
import { couponInputAtom } from "~/components/checkout/fields/atom"
import { usePageCart } from "./usePageCart"

interface Options {
  isEmailFieldDirty?: boolean
  isCouponVisible?: boolean
}

export const useCoupon = ({
  isEmailFieldDirty,
  isCouponVisible = true,
}: Options) => {
  const [couponInput, setCouponInput] = useRecoilState(couponInputAtom)
  const { t } = useTranslation()
  const toast = useToast()
  const {
    pageCart: { couponCodeStatus },
    pageCartActions: { setCoupon },
  } = usePageCart()

  const couponIsLoading = couponCodeStatus === "loading"
  const couponIsValid = couponCodeStatus === "accepted"
  const couponIsInvalid = couponCodeStatus === "rejected"

  const onCouponApply = useCallback(() => {
    setCoupon(couponInput)
  }, [couponInput, setCoupon])

  const onCouponReset = useCallback(
    (reason?: string) => {
      if (reason) {
        toast({
          title: t("hooks.useCoupon.CouponResetToastTitle"),
          description: reason,
          status: "info",
          duration: 3000,
          isClosable: true,
        })
      }
      setCouponInput("")
      setCoupon("")
    },
    [setCoupon, setCouponInput, t, toast]
  )

  useEffectOnce(() => {
    if (!isCouponVisible) {
      setCouponInput("")
    }
  })

  useEffect(() => {
    if (couponIsValid && isEmailFieldDirty) {
      onCouponReset(t("hooks.useCoupon.CouponResetEmailReason"))
    }
  }, [couponIsValid, isEmailFieldDirty, onCouponReset, t])

  return {
    couponIsInvalid,
    couponIsLoading,
    couponIsValid,
    couponInput,
    setCouponInput,
    onCouponApply,
    onCouponReset,
  }
}
