import { Box, Input } from "@chakra-ui/react"
import { CardElement } from "@stripe/react-stripe-js"
import React from "react"

interface Props {}

const StripeInput: React.FC<Props> = () => {
  return (
    <Input as={Box} borderColor="gray.200" px={3} py={2.5} bg="white">
      <CardElement
        options={{
          hidePostalCode: true,
          style: {
            base: {
              fontSize: "16px",
              fontWeight: "300",
            },
          },
        }}
      />
    </Input>
  )
}

export default StripeInput
