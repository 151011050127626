import { useBreakpointValue } from "@chakra-ui/react"
import React from "react"
import { PaymentFields } from "~/interfaces/entities/Payment"
import { usePaymentOption } from "./payment/hooks/usePaymentOption"
import PaymentFormDesktop from "./payment/PaymentFormDesktop"
import PaymentFormMobile from "./payment/PaymentFormMobile"
import StripeElementsWrapper from "./payment/stripe/StripeElementsWrapper"
import PickupFormDetails from "./pickup-forms/PickupFormDetails"

const fields: any[] = [
  "fullName",
  "email",
  "phoneNumber",
  "terms",
  "payment.method",
]

export interface PickupFormFields {
  fullName: string
  email: string
  phoneNumber: string
  country: string
  payment?: PaymentFields
}

interface Props {
  defaultValues: { [key: string]: string }
  orderSuccessPath: string
  onSubmit: (data: PickupFormFields) => void
  onChange: (data: PickupFormFields) => void
  isLoading: boolean
  isDisabled: boolean
  isReady: boolean
}

const PickupForm: React.FC<Props> = props => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })

  const { hasStripe, hasPayInStore } = usePaymentOption()

  const hasPaymentOption = Boolean(hasStripe || hasPayInStore)

  if (!hasPaymentOption) {
    console.error("No payment option available for pickup form")
  }

  return (
    <StripeElementsWrapper>
      {Boolean(isMobile && hasStripe) ? (
        <PaymentFormMobile
          {...props}
          fields={fields}
          FormDetails={PickupFormDetails}
        />
      ) : (
        <PaymentFormDesktop
          {...props}
          fields={fields}
          FormDetails={PickupFormDetails}
        />
      )}
    </StripeElementsWrapper>
  )
}

export default PickupForm
