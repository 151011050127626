import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { navigate } from "gatsby-link"
import { delay } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useOrder } from "~/hooks/useOrder"
import { usePageSession } from "~/hooks/usePageSession"
import { useProcessActions } from "~/hooks/useProcessActions"
import { logger } from "~/services/Logger"

export const useStripePayment = (
  orderSuccessPath: string,
  clientSecret?: string
) => {
  const stripe = useStripe()
  const elements = useElements()
  const { pageSessionActions } = usePageSession()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const process = useProcessActions()
  const { lastOrderId } = useApplicationState()
  const { order } = useOrder(lastOrderId)

  const fetchPaymentStatus = useCallback(async () => {
    if (!stripe || !elements || !clientSecret) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const cardElement = elements?.getElement(CardElement)

    if (!cardElement) {
      return
    }

    if (cardElement) {
      cardElement.focus()
    }

    try {
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)

      switch (paymentIntent?.status) {
        case "succeeded":
          logger.log("Payment succeeded!")
          break
        case "processing":
          logger.log("Processing payment.")
          break
        default:
          logger.log("Awaiting card details.")
          break
      }
    } catch (error: any) {
      logger.error("An error occurred while fetching payment status.")
      setErrorMessage(error?.message)
    }
  }, [clientSecret, elements, stripe])

  useEffect(() => {
    fetchPaymentStatus()
  }, [fetchPaymentStatus])

  //Submit method for CardElement(desktop)
  const onCardPaymentSubmit = useCallback(async () => {
    if (!stripe || !elements || !clientSecret) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setErrorMessage("Payment method didn't load correctly, please try again.")
      return
    }
    setIsLoading(true)

    try {
      const { error } = await stripe.confirmCardPayment(clientSecret!, {
        payment_method: {
          card: elements.getElement(CardElement)!,
        },
      })

      if (error) {
        //payment failed
        logger.error(`payment failure: ${error.message}`)
        setErrorMessage(error.message || "Oops, something went wrong.")
      } else {
        //payment succeeded
        logger.log("payment succeeded!")

        process.fireAnalyticEvent({
          eventType: "purchase",
          data: { order: order.data },
        })

        navigate(`/${orderSuccessPath}`)

        delay(() => {
          // flag page session for reset
          //add delay to prevent the page from flickering
          pageSessionActions.setIsReady(false)
        }, 1000)
      }
    } catch (error: any) {
      setErrorMessage(error?.message)
      logger.error(error?.message)
    }

    setIsLoading(false)
  }, [
    stripe,
    elements,
    clientSecret,
    process,
    order,
    orderSuccessPath,
    pageSessionActions,
  ])

  //Submit method for PaymentElement(mobile)
  const onPaymentSubmit = useCallback(async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setErrorMessage("Payment method didn't load correctly, please try again.")
      return
    }

    setIsLoading(true)

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            address: {
              country: order.data.country,
            },
          },
        },
      },
      redirect: "if_required", //disable the default redirect
    })

    if (result.error) {
      logger.error(`payment failure: ${result.error.message}`)
      setErrorMessage(result.error.message!)
    } else {
      logger.log("payment succeeded!")

      process.fireAnalyticEvent({
        eventType: "purchase",
        data: { order: order.data },
      })

      navigate(`/${orderSuccessPath}`)

      delay(() => {
        // flag page session for reset
        //add delay to prevent the page from flickering
        pageSessionActions.setIsReady(false)
      }, 1000)
    }

    setIsLoading(false)
  }, [elements, order, orderSuccessPath, pageSessionActions, process, stripe])

  return {
    isLoading,
    errorMessage,
    onPaymentSubmit,
    onCardPaymentSubmit,
  }
}
