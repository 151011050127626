import { Stack } from "@chakra-ui/react"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useUnmount } from "react-use"
import { useInitialiseFieldsList } from "~/hooks/useInitialiseFieldsList"
import { unregisterFields } from "~/services/Utils"
import CountryInput from "../fields/CountryInput"
import EmailInput from "../fields/EmailInput"
import FormInput from "../fields/FormInput"
import PhoneInput from "../fields/PhoneInput"
import StateInput from "../fields/StateInput"
import FormStack from "../FormStack"

// keep this list ordered by UI input
const fields = [
  "fullName",
  "email",
  "phoneNumber",
  "line1",
  "city",
  "postcode",
  "state",
  "country",
  "terms",
]

interface Props {}

const DeliveryFormDetailsGB: React.FC<Props> = () => {
  const { t } = useTranslation()
  const {
    register,
    unregister,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext()

  useInitialiseFieldsList(fields)

  useUnmount(() => {
    unregisterFields(unregister, fields)
  })

  const countryCode = watch("country")

  return (
    <FormStack>
      <FormInput
        label={t(
          "components.checkout.delivery-forms.DeliveryFormDetails.GB.FullName"
        )}
        type="text"
        autoComplete="name"
        errors={errors}
        defaultValue={getValues("fullName")}
        {...register("fullName", {
          required: true,
        })}
      />
      <Stack w="full" direction={{ base: "column", md: "row" }} spacing={4}>
        <EmailInput />
        <PhoneInput
          label={t(
            "components.checkout.delivery-forms.DeliveryFormDetails.GB.PhoneNumber"
          )}
          errors={errors}
          countryCode={countryCode}
          defaultValue={getValues("phoneNumber")}
          {...register("phoneNumber", {
            required: true,
          })}
        />
      </Stack>
      <FormInput
        label={t(
          "components.checkout.delivery-forms.DeliveryFormDetails.GB.Line1"
        )}
        type="text"
        autoComplete="address-line1"
        errors={errors}
        defaultValue={getValues("line1")}
        {...register("line1", {
          required: true,
        })}
      />
      <Stack w="full" direction={{ base: "column", md: "row" }} spacing={4}>
        <FormInput
          label={t(
            "components.checkout.delivery-forms.DeliveryFormDetails.GB.City"
          )}
          type="text"
          autoComplete="address-level2"
          errors={errors}
          defaultValue={getValues("city")}
          {...register("city", {
            required: true,
          })}
        />
        <StateInput
          countryCode={countryCode}
          label={t(
            "components.checkout.delivery-forms.DeliveryFormDetails.GB.State"
          )}
          errors={errors}
          {...register("state", {
            required: true,
          })}
        />
      </Stack>
      <Stack w="full" direction={{ base: "column", md: "row" }} spacing={4}>
        <FormInput
          label={t(
            "components.checkout.delivery-forms.DeliveryFormDetails.GB.Postcode"
          )}
          type="text"
          autoComplete="postal-code"
          errors={errors}
          defaultValue={getValues("postcode")}
          {...register("postcode", {
            required: true,
          })}
        />
        <CountryInput
          label={t(
            "components.checkout.delivery-forms.DeliveryFormDetails.GB.Country"
          )}
          errors={errors}
          value={getValues("country")}
          {...register("country", {
            required: true,
          })}
        />
      </Stack>
    </FormStack>
  )
}

export default DeliveryFormDetailsGB
